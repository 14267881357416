@import "../commom/variables";
@import "../mixins/mixins";

.btn {
  &.btn-primario {
    position: relative;

    background-color: $azulClaro;
    border-radius: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    font-family: $ffNexa;
    color: #FFF;
    padding: 14px 40px 10px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background-color: $azulHover;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.btn-cinza {
    position: relative;

    background-color: #E1E1E1;
    border-radius: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    font-family: $ffNexa;
    color: #5D5D5D;
    padding: 14px 14px 10px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background-color: #666;
      color: #FFF !important;
      cursor: pointer;
    }
  }

  &.btn-icon {
    position: relative;
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    color: #5D5D5D;
    padding: 0 4px;
    text-align: center;
    height: auto;

    i {
      color: #5D5D5D;
    }

    &:hover {
      // background-color: #666;
      color: #000;

      i {
        color: #000000;
      }

      cursor: pointer;
    }
  }

  &.btn-login {
    position: relative;
    background-color: $azulClaro;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #FFF;
    font-family: $ffNexa;
    padding: 18px 8px 16px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background-color: #666;
      color: #FFF;
      cursor: pointer;
    }
  }
}

.custom-control-label {
  line-height: 1.5;
}
