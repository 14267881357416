// Imports
@import "styles/mixins/mixins";
@import "styles/commom/variables";
@import "styles/commom/imports";
@import "styles/commom/reboot_fix";
@import "styles/commom/maps";
@import "styles/commom/fonts";
@import "styles/commom/default";
@import "styles/commom/defaults";
@import "styles/commom/loader";
@import "styles/commom/hidden";
//Components
@import "styles/components/switch";
@import "styles/components/checkbox";
@import "styles/components/buttons";
@import "styles/components/inputs";
@import "styles/components/h1";
@import "styles/components/cards";
@import "styles/components/select";
@import "styles/components/table";
@import "styles/components/legend";
@import "styles/commom/ball-atom";
@import "styles/components/pagination";
@import "styles/components/listSubmenu";
@import "styles/components/calendar";
@import "ngx-lightbox/lightbox";


.toolbar-background {
  --background: #F5F5F5
}

.is-invalid {
  border: 1px solid #dc3545 !important;
}


.pointer {
  cursor: pointer;
}

.btn-clean {
  border-radius: 0;
  margin-right: 10px;
  padding: 0.375rem 0;
}

modal {
  .modal-dialog {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-footer {
    display: none;
  }

  .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
  }
}

.box-info {
  @include breakpoint(992px, max) {
    margin-bottom: 30px;
  }

  label {
    display: block;
    width: 100%;
    color: #CFCFCF;
    font-family: $ffNexa;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    font-weight: 700;
  }

  .description {
    width: 100%;
    display: block;
    min-width: 285px;
    max-width: 365px;
    border: 3px solid #797979;
    border-radius: 6px;
    background-color: #FFFFFF;
    text-align: center;
    padding: 10px 20px 5px 20px;
    color: #797979;
    font-weight: 700;
    font-family: $ffNexa;
    font-size: 32px;
    line-height: 38px;
  }
}

.legend {
  margin-bottom: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #686868;
  font-family: $ffNexa;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  vertical-align: middle;

  i {
    font-size: 18px;
    vertical-align: sub;
    margin-right: 5px;
  }
}

.label-detail {
  label {
    color: #CFCFCF;
    font-family: $ffNexa;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .externo {
    color: #797979;
    font-family: $ffNexa;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.no-click-event {
  pointer-events: none;

  label {
    &:before {
      background-color: #e9ecef;
    }
  }
}

@media (max-width: 768px) {
  .fc-toolbar {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .fc-toolbar .fc-left, .fc-toolbar .fc-center, .fc-toolbar .fc-right {
    display: block;
    float: none !important;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

.toast-success {
  background-color: $verde;
}

.toast-warning {
  background-color: $amareloSol;
}

.ngx-toastr {
  white-space: pre-line;
}

.modal-body {
  padding: 20px;
}

.toast-error {
  background-color: $error;
}

.toast-success {
  background-color: $valid;
}

.toast-info {
  background-color: $azulClaro;
}

.toast-warning {
  background-color: $amarelo;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.custom-control {
  min-height: 0;

  &-label {
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.custom-control-input {
  height: 1rem;
}

.custom-control-label::after {
  //position: static;
  //display: inline-block;
  //width: 1rem;
  //height: 1rem;
}
