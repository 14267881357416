@import "../commom/variables";
@import "../mixins/mixins";

.table {
  &.table-bordered {
    border-color: #DFDFDF;

    thead {
      background-color: #66C1EC;
      color: #ffffff;
      text-transform: uppercase;
      font-family: $ffNexa;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;

      tr {
        th {
          padding: 1.1rem .75rem .90rem;
          border-color: #DFDFDF;
          vertical-align: middle;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: $fontDefault;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          vertical-align: middle;
        }

        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
  }
}
