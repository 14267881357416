@import "../commom/variables";
@import "../mixins/mixins";

.cardBlue {
  border-radius: 6px;
  background-color: #89c4f4;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex-direction: column;

  &.amarelo {
    background-color: #dfba49;
  }

  &.cinza {
    background-color: #c6c6c6;
  }

  &.vermelho {
    background-color: #f3565d;
  }

  @include breakpoint(768px, max) {
    margin-bottom: 20px;
    padding: 20px;
  }

  .big {
    font-family: $ffNexa;
    font-weight: bold;
    text-align: center;
    font-size: 56px;
    line-height: 68px;
    @include breakpoint(768px, max) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .nome {
    font-family: $ffNexa;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
  }
}
