@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/code';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/input-group';
@import 'bootstrap/scss/custom-forms';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/utilities';
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs4-alert';
@import '@ng-select/ng-select/themes/default.theme.css';

.form-control {
  $color: $form-feedback-invalid-color;
  $icon: $form-feedback-icon-invalid;

  &.ng-invalid.ng-touched {
    border-color: $color;

    @if $enable-validation-icons {
      padding-right: $input-height-inner !important; // stylelint-disable-line declaration-no-important
      background-image: escape-svg($icon);
      background-repeat: no-repeat;
      background-position: right $input-height-inner-quarter center;
      background-size: $input-height-inner-half $input-height-inner-half;
    }

    &:focus {
      border-color: $color;
      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
    }
  }
}
