.blackscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#FFF, 1);
  z-index: 9999;

  .dTable {
    display: table;
    width: 100%;

    &.full {
      height: 100vh;
    }

    .tCell {
      width: 100%;
      vertical-align: middle;
      text-align: center;
      display: table-cell;

      &.top {
        vertical-align: top;
      }

      &.bottom {
        vertical-align: bottom;
      }

      .dib {
        display: inline-block;
      }

      .carregando {
        max-width: 180px;
      }
    }
  }

  &.internPage {
    background-color: rgba(#FFF, 0.6);
  }
}
