@import "../commom/variables";
@import "../mixins/mixins";

h1 {
  color: $azulClaro;
  display: block;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
  font-family: $ffNexa;
  border-bottom: $azulClaro 1px solid;
}


h3 {
  color: $azulHover;
  display: block;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 18px;
  font-family: $ffNexa;
  border-bottom: #55BCEC 1px solid;
}

h4 {
  color: $azulHover;
  display: block;
  width: 100%;
  padding-bottom: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $ffNexa;
}
