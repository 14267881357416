@import "variables";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

@font-face {
  font-family: 'Nexa';
  src: url($fontsSRC + 'NexaLight.eot');
  src: url($fontsSRC + 'NexaLight.eot?#iefix') format('embedded-opentype'),
  url($fontsSRC + 'NexaLight.svg#NexaLight') format('svg'),
  url($fontsSRC + 'NexaLight.ttf') format('truetype'),
  url($fontsSRC + 'NexaLight.woff') format('woff'),
  url($fontsSRC + 'NexaLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url($fontsSRC + 'NexaBold.eot');
  src: url($fontsSRC + 'NexaBold.eot?#iefix') format('embedded-opentype'),
  url($fontsSRC + 'NexaBold.svg#NexaBold') format('svg'),
  url($fontsSRC + 'NexaBold.ttf') format('truetype'),
  url($fontsSRC + 'NexaBold.woff') format('woff'),
  url($fontsSRC + 'NexaBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
