@import "variables";
@import "../mixins/mixins";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

body {
  font: lineHeight(14px, 120%) $fontDefault;
  overflow-x: hidden !important;
  padding: 0;
  margin: 0;
  width: 100%;
  //width: 100vw;
  height: 100%;
  background-color: #FFF;
  // padding-top: 44px;
  &.blockScroll {
    position: fixed;
    overflow: hidden !important;
    height: 100vh;
  }
}


.actions {
  color: $cinza;
  display: block;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.5;
  font-family: $ffNexa;
  border-bottom: 1px solid $cinza;
}
