@import "../commom/variables";
@import "../mixins/mixins";

.listSubmenu {
  position: relative;
  display: inline-block;
  border-radius: 0;
  font-size: 16px;
  line-height: 20px;
  color: #5D5D5D;
  padding: 0 4px;
  text-align: center;
  height: auto;
  vertical-align: middle;
  cursor: pointer;

  .listMenu {
    position: absolute;
    width: 180px;
    height: auto;
    border-radius: 6px;
    background-color: #D8D8D8;
    z-index: 2;
    right: -16px;
    top: 100%;
    margin-top: 20px;
    display: none;

    &::before {
      content: '';
      background-image: url($img + 'geral/triangle.png');
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: -15px;
      width: 20px;
      height: 15px;
      right: 22px;
    }

    a {
      display: block;
      padding: 10px 12px;
      font-size: 12px;
      line-height: 18px;
      border-bottom: #E9E9E9 1px solid;
      text-align: left;

      i {
        font-size: 14px;
        width: 20px;
      }

      &:hover {
        background-color: $azulHover;
        color: #ffffff;
        text-decoration: none;

        i {
          color: #ffffff;
        }
      }
    }

    &.ativo {
      display: block;
    }
  }
}
