@import "variables";
@import "../mixins/mixins";

a {
  color: #000;
}

.hiddenText {
  text-indent: 1000%;
  white-space: nowrap;
  overflow: hidden;
}

.animeVisibility {
  visibility: hidden;
}

.notAnimated .animeVisibility {
  visibility: visible;
}

.fullWidth {
  display: block;
  width: 100%;
}

.mb0 {
  margin-bottom: 0
}

.mb7 {
  margin-bottom: 7px
}

.mb10 {
  margin-bottom: 10px
}

.mb15 {
  margin-bottom: 15px
}

.mb20 {
  margin-bottom: 20px
}

.mb30 {
  margin-bottom: 30px
}

.mb40 {
  margin-bottom: 40px
}

.mb50 {
  margin-bottom: 50px
}

.pr0 {
  padding-right: 0px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.pl0 {
  padding-left: 0px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.noShow {
  display: none;
}

.container {
  width: 100%;
  max-width: $containerWidth;
}

.error {
  color: $error;
  display: none;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;

  &.show {
    display: block;
  }
}

.errorField {
  border: 1px solid $error !important;
  background-color: #F9F1F1 !important;
}

.desktop {
  @include breakpoint(768px, max) {
    display: none;
  }
}

.mobile {
  display: none !important;
  @include breakpoint(768px, max) {
    display: block !important;
  }
}

select::-ms-expand {
  display: none;
}

.form-bottom {
  border-top: $azulClaro 1px solid;
  margin-top: 20px;
  padding-top: 10px;
}


.invalid {
  border: 1px solid $error !important;
}
