@import "../commom/variables";

pagination-controls {
  margin-top: 20px;
  display: block;
}

.ngx-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  max-width: 600px;
  margin: 0 auto !important;

  li {
    font-family: $ffNexa;
    border-radius: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
}

.pagination-next a,
.pagination-previous a,
.pagination-previous span:not(.show-for-sr) {
  @extend .btn;
  @extend .btn-primario;

  padding: 14px 14px 10px !important;
  border-radius: 6px !important;
  color: $white !important;

  &:hover {
    background-color: $azulClaro !important;
  }

  &:before {
    content: none;
  }
}

.pagination-next,
.pagination-previous,
.pagination-next a,
.pagination-previous a {
  &:before,
  &:after {
    content: none !important;
  }
}

.pagination-previous a,
.pagination-previous.disabled span:not(.show-for-sr) {
  &:before {
    @extend #fa-solid;
    content: '\f060' !important;
    margin-right: 0 !important;
  }
}

.pagination-next a,
.pagination-next.disabled span:not(.show-for-sr) {
  &:before {
    @extend #fa-solid;
    content: '\f061' !important;
    margin-left: 0 !important;
  }
}

.pagination-next.disabled span:not(.show-for-sr),
.pagination-previous.disabled span:not(.show-for-sr) {
  background-color: #cacaca !important;
}

.pagination-previous {
  padding-left: 0 !important;
}
