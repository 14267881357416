/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$white: #ffffff;

$valid: #2ecc71;
$error: #c0392b;

$cinza: #7D7D7D;
$preto: #212529;
$azul: #10243B;

$verde: #018542;
$vermelho: #e74c3c;
$amareloSol: #f1c40f;
$amarelo: #FDC82F;

$disabled: #959495;

$azulClaro: #55BCEC;
$azulHover: #275E78;

//Tamanho Container
$containerWidth: 900px;

//Font Padrão do Site
$fontDefault: 'Open Sans', sans-serif;
$ffNexa: 'Nexa';

$corBarraResponsivo: #000;

// PATHS
$fontsSRC: "/assets/fonts/";
$img: "/assets/imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 320px, mob: 768px, tablet: 1024px), min: (sm: 768px, md: 992px, lg: 1200px));


#fa-solid {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-family: 'Font Awesome 6 Free';
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}
