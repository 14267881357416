@import "../commom/variables";
@import "../mixins/mixins";

form {
  legend {
    display: inline-block;
    border: 0;
    color: #686868;
    font-family: $ffNexa;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 14px;
    font-weight: bold;
    vertical-align: middle;
    margin: 10px 0 24px 0;
    padding: 0;

    i {
      display: inline-block;
      font-size: 16px;
      margin-right: 7px;
    }
  }
}
