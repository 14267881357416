@import "../commom/variables";
@import "../mixins/mixins";

select {
  &.form-control {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    border: #D8D8D8 1px solid;
    box-shadow: none;
    font-family: $ffNexa;
    font-size: 16px;
    line-height: 20px;
    //color: #CFCFCF;
    font-weight: 400;
    padding: 10px 12px;
    height: 45px;
    appearance: none;
    background-image: url($img + 'geral/select.svg');
    background-position: 94% center;
    background-repeat: no-repeat;
    margin-bottom: 12px;
  }
}
