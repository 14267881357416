@import "../commom/variables";
@import "../mixins/mixins";

$lateColor: #f3565d;
$currentColor: #dfba49;
$approvalColor: #89c4f4;
$openedColor: #c6c6c6;

full-calendar {
  .calendar_task {
    color: $preto;
    font-family: $fontDefault;
    cursor: pointer;
    box-sizing: border-box;
  }

  .opened {
    --fc-event-bg-color: #{$openedColor};
    --fc-event-border-color: #{$openedColor};

    color: #ffffff;
  }

  .lates, .late {
    --fc-event-bg-color: #{$lateColor};
    --fc-event-border-color: #{$lateColor};

    color: $white;
  }

  .current {
    --fc-event-bg-color: #{$currentColor};
    --fc-event-border-color: #{$currentColor};

    color: $white;
  }

  .approval {
    --fc-event-bg-color: #{$approvalColor};
    --fc-event-border-color: #{$approvalColor};

    color: $white;
  }
}
