$width: 60px;
$height: 30px;
$padding: 2px;

/* Estilo iOS */
.switch__container {
  width: $width;
}

.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: $padding;
  width: $width;
  height: $height;
  background-color: #dddddd;
  border-radius: $height;
}

.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: $height;
  transition: background 0.4s;
}

.switch--shadow + label:after {
  width: $height - $padding;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch--shadow:checked + label:before {
  background-color: #8ce196;
}

.switch--shadow:checked + label:after {
  transform: translateX($height);
}

/* Estilo Flat */
.switch--flat + label {
  padding: $padding;
  width: 120px;
  height: $height;
  background-color: #dddddd;
  border-radius: $height;
  transition: background 0.4s;
}

.switch--flat + label:before,
.switch--flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

.switch--flat + label:before {
  top: $padding;
  left: $padding;
  bottom: $padding;
  right: $padding;
  background-color: #fff;
  border-radius: $height;
  transition: background 0.4s;
}

.switch--flat + label:after {
  top: $padding * 2;
  left: $padding * 2;
  bottom: $padding * 2;
  width: $height - $padding * 2;
  background-color: #dddddd;
  border-radius: $height - $padding * 4;
  transition: margin 0.4s, background 0.4s;
}

.switch--flat:checked + label {
  background-color: #8ce196;
}

.switch--flat:checked + label:after {
  margin-left: $height;
  background-color: #8ce196;
}
