@import "../commom/variables";
@import "../mixins/mixins";

.form-group {
  label {
    font-family: $ffNexa;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #686868;
  }
}

.form-control {
  font-family: $fontDefault;
  border-radius: 6px;
  background-color: #fff;
  border: #D8D8D8 1px solid;
  box-shadow: none;
  font-size: 16px;
  color: #666;
  font-weight: 400;
  padding: 10px 24px;
  height: auto;
  display: block;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: $ffNexa;
    color: #CFCFCF;
    font-size: 16px;
    line-height: normal;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-family: $ffNexa;
    color: #CFCFCF;
    font-size: 16px;
    line-height: normal;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-family: $ffNexa;
    color: #CFCFCF;
    font-size: 16px;
    line-height: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-family: $ffNexa;
    color: #CFCFCF;
    font-size: 16px;
    line-height: normal;
  }
}

.ngx-datepicker-container {
  .ngx-datepicker-calendar-container {
    z-index: 9999999;
  }

  .topbar-title {
    text-transform: capitalize;
  }

  .is-disabled {
    color: #ffffff;
  }

  .ngx-datepicker-position-bottom-right {
    top: 5px !important;
  }
}

.ng-autocomplete {
  width: 100% !important;

  .ng-select-container {
    box-shadow: none !important;
    width: 100% !important;
    height: 45px !important;
    border-radius: 6px !important;
    border: 1px solid #d8d8d8 !important;
    background-color: #ffffff;

    ng-dropdown-panel {
      z-index: 9999;
    }

    .input-container {
      input {
        height: 100% !important;
        font-family: $ffNexa !important;
        font-size: 16px !important;
        line-height: 1 !important;
        border-radius: 6px !important;
        text-transform: uppercase !important;

        @include placeholder {
          color: #cfcfcf;
        }
      }
    }

    .ng-value-container {
      .ng-input {
        height: 100%;
        top: 0 !important;

        input {
          height: 100%;
        }
      }
    }
  }

  .autocomplete-container {
    box-shadow: none;
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    background-color: #ffffff;

    .suggestions-container {
      z-index: 9999;
    }

    .input-container {
      height: 100%;
      width: 100%;

      input {
        height: 100%;
        font-family: $ffNexa;
        font-size: 16px;
        line-height: 1;
        border-radius: 6px;
        text-transform: uppercase;

        @include placeholder {
          color: #cfcfcf;
        }
      }
    }
  }
}

.ng-select-disabled {
  &.ng-select-container {
    background-color: #e9ecef;
  }
}
